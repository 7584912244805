<template>
  <div class="info" id="watermark">
    <top-bar :title="'场所详情'" :left="true"></top-bar>

    <van-popup v-model="typeShow" position="bottom" >
      <van-cascader
          :field-names="fieldNames"
          v-model="cascaderValue"
          title="请选择"
          :options="list.placeTypeList"
          @close="typeShow = false"
          @change="typeConfirm"
      />
    </van-popup>

    <van-popup
        v-model="houseTypeShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.placeHouseTypeList"
          value-key="label"
          @confirm="houseTypeConfirm"
          @cancel="houseTypeShow = false"
      />
    </van-popup>

    <van-popup
        v-model="importantShow"
        position="bottom">
      <van-picker
          v-model="importantShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.isList"
          @cancel="importantShow = false"
          @confirm="importantConfirm"
      />
    </van-popup>

    <van-popup
        v-model="importantTypeShow"
        position="bottom">
      <van-picker
          v-model="importantTypeShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.importantTypeList"
          @cancel="importantTypeShow = false"
          @confirm="importantTypeConfirm"
      />
    </van-popup>

    <van-popup
        v-model="trinityShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.isList"
          value-key="label"
          @confirm="trinityConfirm"
          @cancel="trinityShow = false"
      />
    </van-popup>

    <van-popup
        v-model="subareaShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.orgList"
          value-key="label"
          @confirm="subareaConfirm"
          @cancel="subareaShow = false"
      />
    </van-popup>

    <van-popup
        v-model="codeShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.codeList"
          value-key="label"
          @confirm="codeConfirm"
          @cancel="codeShow = false"
      />
    </van-popup>

<!--    <van-popup-->
<!--        v-model="buildingShow"-->
<!--        position="bottom">-->
<!--      <van-picker-->
<!--          title="请选择"-->
<!--          show-toolbar-->
<!--          :columns="list.publicBuildingList"-->
<!--          value-key="label"-->
<!--          @confirm="buildingConfirm"-->
<!--          @cancel="buildingShow = false"-->
<!--      />-->
<!--    </van-popup>-->

    <!-- 选择地址 -->
    <van-popup v-model="buildingShow" position="bottom" @click-overlay="buildingOverlay">
      <van-cascader v-model="dataForm.building" title="请选择" :options="list.buildingList"
                    @close="buildingShow = false" @change="buildingFinish" :field-names="fieldNames"/>
    </van-popup>

    <div v-show="!gridShow">
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">基本信息</span>
          </template>
        </van-cell>
        <van-cell title="所属机构" :value="selectMsg.subareaStr" @click="disable ? (subareaShow = false) : (subareaShow = true)"
                  :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" :is-link="!disable" required/>
        <van-cell title="所属楼幢房屋" :value="selectMsg.buildingStr" @click="disable ? (buildingShow = false) : (buildingShow = true)"
                  :value-class="{'value-common':selectMsg.buildingStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="楼幢房屋类型" required :value="selectMsg.houseTypeStr" @click="disable ? (houseTypeShow = false) : (houseTypeShow = true)"
                  :value-class="{'value-common':selectMsg.houseTypeStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="场所类型" required :value="selectMsg.placeTypeStr" @click="disable ? (typeShow = false) : (typeShow = true)"
                  :value-class="{'value-common':selectMsg.placeTypeStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="常用名称" required>
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.name" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="正式名称">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.licenseName" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="面积(/m²)">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.areas" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="职工人数">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.staffNum" placeholder="请输入" type="digit" style="text-align: right"/>
        </van-cell>
        <van-cell title="是否重要场所" required :value="selectMsg.importantStr" @click="disable ? (importantShow = false) : (importantShow = true)"
                  :value-class="{'value-common':selectMsg.importantStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="是否三合一场所" required :value="selectMsg.trinityStr" @click="disable ? (trinityShow = false) : (trinityShow = true)"
                  :value-class="{'value-common':selectMsg.trinityStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="重点属性" required :value="selectMsg.importantTypeStr" @click="disable ? (importantTypeShow = false) : (importantTypeShow = true)"
                  :value-class="{'value-common':selectMsg.importantTypeStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="风险等级">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.level" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="用途">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.purpose" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="主管单位">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.competentUnit" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="所属网格/微网格" :value="selectMsg.gridName || '请选择'" @click="disable ? (gridShow = false) : (gridShow = true)"
                  :value-class="{'value-common':selectMsg.gridName=='请选择'}" :is-link="!disable" v-if="!disable"/>
        <van-cell
            readonly
            clickable
            name="场所标签"
            :value="selectMsg.labelStr || '请选择'"
            title="场所标签"
            :is-link="!disable"
            @click="showlabel"
        />
        <van-popup v-model="label" position="bottom" @click-overlay="labelOverlay">
          <van-cascader
              title="请选择"
              value="value"
              :field-names="{ text: 'label', value: 'value', children: 'children' }"
              :options="list.userCommunityLabelTree"
              active-color="#1989fa"
              @close="labelClose"
              @change="labelFinish"
          />
        </van-popup>

        <van-cell hover-class="none" v-if="labels.length>0">
          <template v-for="item in labels">
            <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
              <span :style="disable ? 'border: none' : ''">{{ item.name }}</span>
              <van-icon style="padding: 0 10px" name="cross" v-if="!disable" @click="removeLabel(item.value)"/>
            </div>
          </template>
        </van-cell>
        <template v-if="isCodeShow">
          <van-cell title="标准地址名称">
            <van-field clearable type="textarea" autosize rows="1" clear-trigger="always" :readonly="disable" v-model="selectMsg.codeName"
                       @blur="dataForm.address = dataForm.codeName" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <p v-if="isTipShow" style="line-height: 0.3rem;margin: 0.1rem 0 0 0.5rem;color: #F56C6C;font-size: 14px;">{{tipValue}}</p>
          <van-cell title="标准地址码" :value="selectMsg.codeStr || '请选择'" @click="disable ? false : getCode()"
                    :value-class="{'value-common':selectMsg.codeStr=='请选择'}" :is-link="!disable"/>
        </template>
        <van-cell title="详细地址" required>
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.address" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="备注" :border="false" class="cellClass"/>
        <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.remark" placeholder="请输入" style="text-align: right"/>
        <van-row class="uploadBox">
          <van-col :span="24">
            <van-uploader
                v-model="urls"
                :readonly="disable"
                @delete="uploaddel"
                :after-read="uploadRead"
                :deletable="!disable"
                :show-upload="!disable"
            />
          </van-col>
        </van-row>

      </van-cell-group>

      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">联络信息</span>
            <div style="color:#387FF5;float:right" @click="firstVisible=!firstVisible"><van-icon :name="firstVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="firstVisible">
          <van-cell title="主要联系人" required>
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.contact" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="联系人电话" required>
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.contactMobile" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="法人/负责人">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.legal" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="法人/负责人电话">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.legalMobile" placeholder="请输入" style="text-align: right"/>
          </van-cell>
        </div>
      </van-cell-group>

      <!-- 自定义字段 -->
      <field-view ref="fieldView" :disabled="disable" :id="dataForm.id" />


      <van-row class="btns">
        <van-col :span="24" v-if="!disable">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
        <van-row gutter="10" v-else>
          <van-col :span="12">
            <van-button plain type="danger" size="large" round @click="deleteClick" style="background-color:transparent">删除</van-button>
          </van-col>
          <van-col :span="12">
            <van-button type="info" size="large" round @click="disable=!disable">编辑</van-button>
          </van-col>
        </van-row>
      </van-row>
    </div>
    <grid-select v-show="gridShow" :list="list.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { getbelongGrid, getDictTree, getbelongSubArea, getVirtualDict, listComRightLabel, getMiniGrid } from "@/utils/common";
import { isNumber } from "@/utils/validate";
import fieldView from '../../components/field-view/field-view.vue';
import { getImageStream, formatLabel } from "@/utils/index";
import GridSelect from '../userRes/grid-select.vue'
let that
export default {
  components: {
    topBar,
    fieldView,
    GridSelect
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    if (this.$route.query.id) {
      (this.disable = true), (this.dataForm.id = this.$route.query.id);
    }
    this.street = this.$globalData.userInfo.street
    this.initOrgId = this.$globalData.userInfo.orgId
    this.isCodeShow = this.$globalData.userInfo.codeType == 2 ? true : false
    that = this;
    getbelongSubArea(this.$orgId, function (e) {
      that.list.orgList = that.list.orgList.concat(e)
    });
    getDictTree({ code: "placeType" }, function (e) {
      that.list.placeTypeList = that.list.placeTypeList.concat(e);
    });
    getVirtualDict('importantType', function (e) {
      that.list.importantTypeList = e
    })
    listComRightLabel(function (labels) {
      that.list.userCommunityLabelTree = labels
    }, 3)
    getMiniGrid({community:this.$orgId}, function (list) {
      that.list.gridList = list
    })
    that.getPlaceHouseType();
  },
  data() {
    return {
      firstVisible: true,
      secondVisible: true,
      cascaderValue: [],
      disable: false,
      typeShow: false,
      houseTypeShow: false,
      importantShow: false,
      importantTypeShow: false,
      trinityShow: false,
      subareaShow: false,
      buildingShow: false,
      gridShow: false,
      codeShow: false,
      isCodeShow: false,
      isTipShow: false,
      tipValue: '',
      fileList: [],
      street: 0,
      initOrgId: 0,
      codeMappingId: 0,
      dataForm: {
        id: "",
        name: "",
        licenseName: '',
        type: "287",
        houseType: "",
        contact: "",
        contactMobile: "",
        legal: "",
        legalMobile: "",
        important: "0",
        trinity: "0",
        address: "",
        community: '',
        subarea: "",
        buildingId: "",
        competentUnit: '',
        remark: "",
        imgUrl: "",
        newFileMessages: [],
        deleteFiles: [],
        importantType: '',
        staffNum: '',
        labels: [],
        gridId: 0,
        miniGridId: 0,
        purpose: '',
        level: '',
        code: '',
        buildingDepth: 0
      },
      label: false,
      labels: [],
      temporaryLabel: null,
      temporaryBuilding: null,
      urls: [],
      selectMsg: {
        placeTypeStr: "请选择",
        houseTypeStr: "请选择",
        trinityStr: "否",
        importantStr: "否",
        communityStr: "未知",
        subareaStr: "请选择",
        buildingStr: "无",
        hasLicenseStr: "否",
        importantTypeStr: '请选择',
        safetyPerilStr: '请选择',
        labelStr: '请选择',
        gridName: '请选择',
        gridUser: '',
        codeStr: '请选择',
        codeName: ''
      },
      list: {
        isList: [
          { value: "0", label: "否" },
          { value: "1", label: "是" },
        ],
        orgList: [],
        gridList: [],
        placeTypeList: [],
        placeHouseTypeList: [],
        publicBuildingList: [],
        importantTypeList: [],
        userCommunityLabelTree: [],
        buildingList: [],
        codeList: []
      },
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
    };
  },
  watch: {
    'selectData.buildingList': {
      handler(newarr, oldarr) {
        if (this.$route.query.ids) {
          if (newarr !== []) {
            let selectedOptions = []
            let ids = this.$route.query.ids.split('-')
            ids.map(item => {
              if (item) {
                return item
              }
            })
            this.getIdsList(ids, 0, this.list.buildingList, selectedOptions)
            let obj = {
              selectedOptions,
              value: selectedOptions[selectedOptions.length - 1].value
            }
            this.buildingFinish(obj)
          }
        }
      }
    },
  },
  methods: {
    showlabel() {
      if (!this.disable) {
        this.label = true
      }
    },
    labelOverlay() {
      let temporaryLabel = this.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }
      temporaryLabel["delete"] = false
      this.labels.push(temporaryLabel)
      this.dataForm.labels.push(temporaryLabel.ids)
    },
    labelClose(e) {
      this.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = formatLabel(map, false)
      this.temporaryLabel = temporaryLabel
    },
    removeLabel(value) {
      for (let i in this.dataForm.labels) {
        let label = this.dataForm.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          this.dataForm.labels.splice(i, 1)
        }
      }

      for (let i in this.labels) {
        let label = this.labels[i]
        if (label.value == value) {
          this.labels[i]["delete"] = true
        }
      }
      this.dataForm.labels = this.dataForm.labels
      this.labels = this.labels
      this.$forceUpdate()
    },
    getPlaceHouseType() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/dict/virtual/placeHouseType"),
        method: "get",
      }).then(({ data }) => {
        if (data.code == 0) {
          for (let i in data.placeHouseTypes) {
            let placeHouseType = data.placeHouseTypes[i];
            placeHouseType["label"] = placeHouseType.label;
            placeHouseType["value"] = placeHouseType.value + "";
          }
          this.list.placeHouseTypeList = data.placeHouseTypes;
        }
      });
    },
    // 经营类型选择
    typeConfirm({ selectedOptions }) {
      let value = selectedOptions[selectedOptions.length - 1];
      if (value) {
        this.selectMsg.placeTypeStr = value.label;
        this.dataForm.type = value.value;
      }
      if (!value.children || value.children.length == 0) {
        this.typeShow = false;
      }
    },
    // 场所房屋类型选择
    houseTypeConfirm(value) {
      if (value) {
        this.selectMsg.houseTypeStr = value.label;
        this.dataForm.houseType = value.value;
      }
      this.houseTypeShow = false;
    },
    // 是否重要场所选择
    importantConfirm(value) {
      if (value) {
        this.selectMsg.importantStr = value.label;
        this.dataForm.important = value.value;
      }
      this.importantShow = false;
    },
    importantTypeConfirm(value) {
      if (value) {
        this.selectMsg.importantTypeStr = value.label;
        this.dataForm.importantType = value.value;
      }
      this.importantTypeShow = false;
    },
    // 是否三合一场所选择
    trinityConfirm(value) {
      if (value) {
        this.selectMsg.trinityStr = value.label;
        this.dataForm.trinity = value.value;
      }
      this.trinityShow = false;
    },
    // 所属小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.buildingStr = '请选择'
        this.dataForm.buildingId = ''
        this.list.buildingList = []
        this.selectMsg.subareaStr = value.label;
        if (this.street == this.initOrgId) {
          this.dataForm.community = value.value;
        } else {
          this.dataForm.subarea = value.value;
        }
      }
      this.subareaShow = false;
      this.getBuildingList(value.value);
    },
    // 详细地址选择
    buildingFinish(value) {
      let options = value.selectedOptions
      this.selectMsg.buildingStr = '请选择'
      this.dataForm.buildingId = ''
      let option = options[options.length - 1];
      if (option.depth !== 1 && option.depth !== 3) {
        let map = {}
        map["value"] = option.value
        map['label'] = option.label
        this.temporaryBuilding = map
        let type = option.depth == 2 ? 1 : 2
        this.dataForm.buildingDepth = option.depth
        this.getCodeById(option.value, type)
      }
    },
    buildingOverlay() {
      if (this.temporaryBuilding && this.temporaryBuilding.value !== '') {
        this.selectMsg.buildingStr = this.temporaryBuilding.label
        this.dataForm.buildingId = this.temporaryBuilding.value
      }
    },
    // //所属网格选择
    // gridConfirm(value) {
    //   if (value) {
    //     this.selectMsg.gridName = value.name;
    //     this.dataForm.gridId = value.id;
    //   }
    //   this.gridShow = false;
    // },
    // 网格选择
    selectGrid (e) {
      this.dataForm.gridId = 0
      this.dataForm.miniGridId = 0
      const {selectList, labelCondition, depth} = e
      this.selectMsg.gridName = labelCondition[0].label;
      this.dataForm.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
      this.dataForm.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
      this.gridShow = false
    },
    codeConfirm (value){
      if (value) {
        this.selectMsg.codeStr = value.value;
        this.dataForm.code = value.value;
        this.selectMsg.codeName = value.label
        this.dataForm.address = value.label
        this.list.codeList.map(item => {
          if (item.value == value.value) {
            this.codeMappingId = item.codeMappingId
          }
        })
      }
      this.codeShow = false;
    },
    getCode() {
      this.list.codeList = []
      this.selectMsg.codeStr = '请选择'
      this.dataForm.code = ''
      this.isTipShow = false
      this.tipValue = ''
      let url = '/wxapp/sys/addressCodeMapping/listByName'
      let name = true
      if (isNumber(this.selectMsg.codeName)) {
        url = '/wxapp/sys/addressCodeMapping/infoByCode'
        name = false
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        params: this.$http.adornParams({
          name: name ? this.selectMsg.codeName : undefined,
          code: name ? undefined : this.selectMsg.codeName,
          type: 4
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = (data.list || data.sysAddressCodeMapping).map(item => {
            return {
              value: item.houseCode ? item.houseCode : item.doorCode,
              label: item.fullName,
              codeMappingId: item.id
            }
          })
          if (name) {
            this.list.codeList = list
          } else {
            this.list.codeList = list
          }
        this.codeShow = true
        } else {
          this.isTipShow = true
          this.tipValue = data.msg
          // this.$toast.fail(data.msg)
        }
      })
    },
    // 获取房屋列表
    getBuildingList(orgId) {
      if (this.street == this.initOrgId) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/liveWhichTree'),
          method: 'get',
          params: this.$http.adornParams({
            communityId: orgId,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
      } else {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/bySubareaIdTree'),
          method: 'get',
          params: this.$http.adornParams({
            subareaId: orgId,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }
    },
    getCodeById (id, type) {
      // 1 楼幢, 2 房屋
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/addressCodeMapping/infoByIdAndType`),
        method: 'get',
        params: this.$http.adornParams({
          id: id,
          type: type
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.one) {
            if (type == 1) {
              this.dataForm.code = data.one.buildingCode
              this.selectMsg.codeStr = data.one.buildingCode
            } else {
              this.dataForm.code = data.one.houseCode
              this.selectMsg.codeStr = data.one.houseCode
            }
            this.selectMsg.codeName = data.one.fullName
            this.codeMappingId = data.one.id
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 删除图片
    uploaddel(file, detail) {
      let imgUrls = this.dataForm.imgUrl.split(",");
      imgUrls.splice(detail, 1);
      this.dataForm.imgUrl = imgUrls.join();
    },
    // 上传图片
    uploadRead(file) {
      let that = this;
      this.$toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", `files/place/${this.$orgId}`);
        this.$http({
          url: this.$http.adornUrl("/wxapp/file/upload"),
          method: "post",
          data: formdata,
        }).then(({ data }) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            console.log(this.urls);
            // let uid = that.urls.length + 1;
            // let u = getImageStream(data.fileMessage.relativePath);
            // let url = {
            //   uid: uid,
            //   status: "done",
            //   url: u,
            // };
            // that.urls.push(url);
            that.dataForm.imgUrl = that.dataForm.imgUrl
                ? that.dataForm.imgUrl + "," + data.fileMessage.relativePath
                : data.fileMessage.relativePath;

            // this.dataForm.imgUrl += ','+data.fileMessage.relativePath;

            // this.dataForm.newFileMessages.push(data.fileMessage);
            return true;
          } else {
            that.$toast.fail(data.msg);
          }
        });
      })
    },
    async submit() {
      let url = "/wxapp/place/info/save";
      if (this.dataForm.id && this.dataForm.id != 0) {
        url = "/wxapp/place/info/update";
      }
      if (this.dataForm.subarea === '' && this.dataForm.community === '') {return this.$toast.fail('失败:请选择小区');}
      if (this.dataForm.name === '') {return this.$toast.fail('失败:场所名称不能为空');}
      if (this.dataForm.important === '') {return this.$toast.fail('失败:是否重要场所不能为空');}
      if (this.dataForm.importantType === '') {return this.$toast.fail('失败:重点属性不能为空');}
      if (this.dataForm.type === '') {return this.$toast.fail('失败:场所类型不能为空');}
      if (this.dataForm.houseType === '') {return this.$toast.fail('失败:场所房屋类型不能为空');}
      if (this.dataForm.trinity === '') {return this.$toast.fail('失败:是否三合一场所不能为空');}
      if (this.dataForm.address === '') {return this.$toast.fail('失败:详细地址不能为空');}
      if (this.dataForm.contact === '') {return this.$toast.fail('失败:主要联系人不能为空');}
      if (this.dataForm.contactMobile === '') {return this.$toast.fail('失败:联系人电话不能为空');}

      // 自定义字段参数
      let extendInfoList = await this.$refs.fieldView.submitForm()
      let buildingDepth = null
      if (this.dataForm.buildingDepth == 2) {
        buildingDepth = 1
      } else if (this.dataForm.buildingDepth == 4) {
        buildingDepth = 3
      }
      this.dataForm.buildingDepth = buildingDepth
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: {...this.dataForm, extendInfoList, labels: this.dataForm.labels.join(','), codeMappingId: this.codeMappingId || undefined,},
      }).then(({ data }) => {
        if (data.code == 0) {
          localStorage.setItem('isRefresh', 1)
          this.$toast.success("新增成功");
          this.$router.go(-1);
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    deleteClick() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/place/info/delete"),
        method: "post",
        params: this.$http.adornParams({
          ids: this.dataForm.id,
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success("删除成功");
          this.$router.go(-1);
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
.info >>> .van-radio-group {
  float: unset !important;
}
</style>
<style lang="scss" scoped>
.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}
</style>
